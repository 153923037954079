import React from 'react';
import './ProjectIntro.css';

const ProjectIntro = (props) => {
   return (
      <section className="leader">

         <h2 className="leader-line">{props.line || ""}</h2>

         {props.lineTwo &&
            <h2 className="leader-line">{props.lineTwo}</h2>
         }

      </section>
   )
}

export default ProjectIntro;
