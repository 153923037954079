import React from 'react';
import './Divider.css';

class Divider extends React.Component {

   render() {
      return (
         <span className="divider"></span>
      )
   }
}

export default Divider;
