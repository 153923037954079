import React from 'react';
import Preview from '../../../components/elements/Preview';
import ProjectsData from '../../../json/projectsData';
import './Previews.css';

const Previews = () => {
   const data = ProjectsData;

   return (
      <section className="previews">
         <Preview data={data.clayton} />
         <Preview data={data.diageo} />
         <Preview data={data.beats} />
         <Preview data={data.giide} />
         <Preview data={data.fridays} />
      </section>
   )
}

export default Previews;
