import React from "react";
import smoothscroll from 'smoothscroll-polyfill';
import { withRouter } from "react-router-dom";

smoothscroll.polyfill();

class ScrollToTop extends React.Component {
   componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
         window.scrollTo({ top: 0, behavior: 'smooth' })
      }
   }

   render() {
      return null;
   }
}

export default withRouter(ScrollToTop);
