import React from 'react';
import MastheadText from '../../../components/elements/MastheadText';
import Container from '../../../components/elements/Container';
import ProjectIntro from '../../../components/elements/ProjectIntro';
import Button from '../../../components/elements/Button';
import Divider from '../../../components/elements/Divider';

import './FourOhFour.css';

class FourOhFour extends React.Component {
   render() {
      return (
         <Container>

            <MastheadText title="404" />

            <div className="container-inner">

               <ProjectIntro
                  line="These aren't the pages you're looking for."
               />

               <Divider />

               <Button href="/" label="Return Home" />

            </div>

         </Container>
      )
   }
}

export default FourOhFour;
