module.exports = {

   clayton: {
      title: "Clayton\nHomes",
      route: "clayton",
      preview: {
         type: "video",
         src: "../../../../videos/clayton/floorplan-rotate.mp4"
      }
   },
   beats: {
      title: "Beats\nby Dre",
      route: "beats"
   },
   giide: {
      title: "Giide",
      route: "giide"
   },
   fridays: {
      title: "Fridays",
      route: "fridays"
   },
   diageo: {
      title: "Diageo",
      route: "diageo"
   },


}
