import React from 'react';
import './Overlay.css';

class Overlay extends React.Component {
   render() {
      return (
         <div className="overlay">

            <div className="overlay-inner" id="overlayInner">

               <section className="overlay-section">

                  <div className="column">
                     <h4 className="column-header">Recent Work</h4>
                     <div className="column-content">
                        <a href="/clayton" className="project-link clayton">Clayton Homes</a>
                        <a href="/diageo" className="project-link diageo">Diageo</a>
                        <a href="/beats" className="project-link beats">Beats by Dre</a>
                        <a href="/giide" className="project-link giide">Giide</a>
                        <a href="/fridays" className="project-link fridays">Fridays</a>
                     </div>
                  </div>

               </section>

               <section className="overlay-section">

                  <div className="column half">
                     <h4 className="column-header">Contact</h4>
                     <div className="column-content contact">
                        <span className="email">hello@zachthornton.com</span>
                        <a className="resume" href="/zach-thornton-resume.pdf" target="_blank" rel="noopener noreferrer">view resume <small>(.pdf)</small></a>
                        <div className="social">
                           <a className="social-icon github" href="https://github.com/zthornto" target="_blank" rel="noopener noreferrer" title="Zach Thornton on GitHub">GitHub</a>
                           <a className="social-icon linkedin" href="https://linkedin.com/in/zach-thornton-denver" target="_blank" rel="noopener noreferrer" title="Zach Thornton on LinkedIn">LinkedIn</a>
                        </div>
                     </div>
                  </div>

                  <div className="column half">
                     <h4 className="column-header">About</h4>
                     <div className="column-content">
                        <p className="blurb">
                           Experienced frontend developer and all-around tech advocate,
                           primarily focused on building beautiful, highly functional
                           interfaces and working with brands to expand or solidify
                           their digital offering.
                        </p>
                     </div>
                  </div>

               </section>

            </div>
         </div>
      )
   }
}

export default Overlay;
