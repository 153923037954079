import React from 'react';
import './MastheadText.css';

class MastheadText extends React.Component {

   render() {
      if (this.props.currentPage === "home") {
         return (
            <div className="masthead-text">
               <h1 className="masthead-text-header">
                  Zach<br />Thornton
               </h1>
            </div>
         )
      } else {
         return (
            <div className="masthead-text">
               <h2 className="masthead-text-header">
                  {this.props.title}
               </h2>
            </div>
         )
      }
   }
}

export default MastheadText;
