import React from 'react';
import { Link } from "react-router-dom";
import './BottomNav.css';

class BottomNav extends React.Component {

   render() {
      return (
         <div className="bottom-nav">
            <Link className="button-prev" to={this.props.prevRoute || "/"}>{this.props.prevTitle || "Prev"}</Link>
            <Link className="button-next" to={this.props.nextRoute || "/"}>{this.props.nextTitle || "Next"}</Link>
         </div>
      )
   }
}

export default BottomNav;
