import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Base from '../../../videos/base.mp4';
import Clayton from '../../../videos/clayton.mp4';
import Beats from '../../../videos/beats.mp4';
import Giide from '../../../videos/giide.mp4';
import Fridays from '../../../videos/fridays.mp4';
import Diageo from '../../../videos/diageo.mp4';

import './MastheadVideo.css';

class MastheadVideo extends React.Component {
   render() {
      const route = this.props.currentRoute;

      return (
         <div className="masthead-video">

            <video className="masthead-video-clip base" src={Base} autoPlay loop muted playsInline />

            <AnimatePresence>
               {route === '/clayton' &&
                  <motion.video initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="masthead-video-clip middle" src={Clayton} autoPlay loop muted playsInline />
               }

               {route === '/beats' &&
                  <motion.video initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="masthead-video-clip middle" src={Beats} autoPlay loop muted playsInline />
               }

               {route === '/giide' &&
                  <motion.video initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="masthead-video-clip middle" src={Giide} autoPlay loop muted playsInline />
               }

               {route === '/fridays' &&
                  <motion.video initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="masthead-video-clip middle" src={Fridays} autoPlay loop muted playsInline />
               }

               {route === '/diageo' &&
                  <motion.video initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="masthead-video-clip middle" src={Diageo} autoPlay loop muted playsInline />
               }
            </AnimatePresence>

         </div>
      )
   }
}

export default MastheadVideo;
