import React from 'react';
import { withRouter} from 'react-router-dom';
import { Link } from "react-router-dom";
import './Header.css';

class Header extends React.Component {
   constructor() {
      super();

      this.state = {
         overlayOpen: false
      }

      this.toggleOverlay = this.toggleOverlay.bind(this);
      this.closeOverlay = this.closeOverlay.bind(this);
   }

   toggleOverlay() {
      this.setState({
         overlayOpen: !this.state.overlayOpen
      });
   }

   closeOverlay() {
      this.setState({
         overlayOpen: false
      });
   }

   componentDidUpdate() {
      const overlayInner = document.getElementById('overlayInner');

      if (this.state.overlayOpen) {
         document.body.classList.add('overlay-open')
         document.body.style.overflow = 'hidden';
      } else {
         document.body.classList.remove('overlay-open');
         document.body.style.overflow = '';
      }

      // delay until transition completes
      setTimeout(function() {
         overlayInner.scrollTop = 0;
      }, 900);

   }

   render() {
      const currentRoute = this.props.location.pathname;

      return (
         <header className="header">
            <div className="header-inner">
               {currentRoute !== "/" &&
                  <Link className="button-back" to="/" onClick={this.closeOverlay}>Home</Link>
               }

               <span className="hamburger" onClick={this.toggleOverlay}>
                  <span className="hamburger-line"></span>
                  <span className="hamburger-line"></span>
                  <span className="hamburger-line"></span>
               </span>
            </div>
         </header>
      )
   }
}

export default withRouter(Header);
