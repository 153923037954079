import React from 'react';
import { motion } from 'framer-motion';
import './Container.css';

const isFirefox = typeof InstallTrigger !== 'undefined';

const pageVariants = {
   in: {
      opacity: 1,
      y: 0
   },
   out: {
      opacity: isFirefox ? 1 : 0,
      y: isFirefox ? 0 : "20vh"
   }
}

const pageTransition = {
   duration: isFirefox ? 0 : 0.8,
   ease: [0.645, 0.045, 0.355, 1],
   type: "tween"
}

class Container extends React.Component {

   render() {
      return (
         <motion.section
            className={"container " + this.props.class}
            initial="out"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
         >
            {this.props.children}
         </motion.section>
      )
   }
}

export default Container;
