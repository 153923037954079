import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga';

import ScrollToTop from './components/elements/ScrollToTop';
import MastheadVideo from './components/elements/MastheadVideo';
import Header from './components/elements/Header';
import Overlay from './components/elements/Overlay';
import Footer from './components/elements/Footer';

import Home from './components/pages/Home';
import Project from './components/pages/Project';
import FourOhFour from './components/pages/FourOhFour';

import './App.css';

import dataClayton from './json/clayton.json';
import dataDiageo from './json/diageo.json';
import dataBeats from './json/beats.json';
import dataGiide from './json/giide.json';
import dataFridays from './json/fridays.json';

ReactGA.initialize('UA-38157691-1');

const App = () => {
   const location = useLocation();
   const isFirefox = typeof InstallTrigger !== 'undefined';

   return (
      <div className="App">
         <ScrollToTop />

         <Header />

         <MastheadVideo currentRoute={location.pathname} />

         <AnimatePresence exitBeforeEnter={isFirefox ? false : true} initial={true}>
            <Switch location={location} key={location.pathname}>
               <Route path='/' component={Home} exact={true} />
               <Route path='/clayton' render={() => <Project data={dataClayton} />} />
               <Route path='/diageo' render={() => <Project data={dataDiageo} />} />
               <Route path='/beats' render={() => <Project data={dataBeats} />} />
               <Route path='/giide' render={() => <Project data={dataGiide} />} />
               <Route path='/fridays' render={() => <Project data={dataFridays} />} />
               <Route component={FourOhFour} />
            </Switch>
         </AnimatePresence>

         <Overlay />

         <Footer />

      </div>
   );
}

export default App;
