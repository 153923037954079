import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <small className="legal">Copyright &copy; 2022 Zach Thornton</small>
      </div>
    </footer>
  )
}

export default Footer;
