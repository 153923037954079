import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from "react-router-dom"
import './Preview.css'

const Preview = (props) => {
   const data = props.data

   const [ref, inView] = useInView({
      threshold: 0.2,
      triggerOnce: true
   })

   function handleHover() {
      console.log('hover');
   }

   return (
      <div ref={ref} className={"preview " + data.route + (inView ? ' in-view' : '')}>

         <Link className="preview-copy" to={data.route} onMouseEnter={handleHover}>
            <h3 className="preview-copy-title">{data.title}</h3>
            <span className="preview-copy-label" >View Project</span>
         </Link>

         <div className="preview-visual"></div>

      </div>
   )
}

export default Preview
