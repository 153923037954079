import React from 'react';
import './ProjectDetails.css';

const ProjectDetails = (props) => {
   return (
      <section className="context">

         {props.type &&
            <h4 className="context-line">{props.type}</h4>
         }

         {props.role &&
            <h4 className="context-line">{props.role}</h4>
         }

      </section>
   )
}

export default ProjectDetails;
