import React from 'react';

import Container from '../../../components/elements/Container';
import MastheadText from '../../../components/elements/MastheadText';
import ProjectIntro from '../../../components/elements/ProjectIntro';
import ProjectDetails from '../../../components/elements/ProjectDetails';
import ProjectSample from '../../../components/elements/ProjectSample';
import Divider from '../../../components/elements/Divider';
import BottomNav from '../../../components/elements/BottomNav';

import './Project.css';


const Project = (props) => {

   const data = props.data;
   if (!data) return;

   const projectSamples = data.samples.map((item, index) => <ProjectSample data={item} key={index} />)

   return (
      <Container class={data.title || ""}>

         <MastheadText title={data.prettyTitle || ""} />

         <div className="container-inner">

            <ProjectIntro line={data.intro || ""} />

            <ProjectDetails type={data.type || ""} role={data.role || ""} />

            <Divider />

            {projectSamples}

            <BottomNav
               prevTitle={data.bottomNav.prevTitle || "Prev"}
               prevRoute={data.bottomNav.prevRoute || "/"}
               nextTitle={data.bottomNav.nextTitle || "Next"}
               nextRoute={data.bottomNav.nextRoute || "/"}
            />

         </div>

      </Container>
   )
}

export default Project;
