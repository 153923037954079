import React from 'react';
import { motion } from 'framer-motion';
import './Button.css';

class Button extends React.Component {

   render() {
      return (
         <motion.a className="button" href={this.props.href} target={this.props.target || "_self"} whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
            {this.props.label || ""}
         </motion.a>
      )
   }
}

export default Button;
