import React from 'react';
import { useInView } from 'react-intersection-observer';
import Button from '../../../components/elements/Button';
import './ProjectSample.css';

const ProjectSample = (props) => {
   const data = props.data;

   const [sample, inView] = useInView({
      threshold: 0.2,
      triggerOnce: true
   });

   return (
      <div ref={sample} className={"sample" + (inView ? ' in-view' : '')}>

         <SampleMedia data={data.media} />

         <div className="sample-copy">
            <h4 className="sample-copy-title">{data.header}</h4>
            <p className="sample-copy-blurb">
               {data.blurb}
            </p>
            <p className="sample-copy-blurb">
               {data.blurbTwo}
            </p>
            {data.link &&
               <Button href={data.link.href} target="_blank" label={data.link.label} />
            }
         </div>
      </div>
   )
}

const SampleMedia = (props) => {
   const isVideo = props.data.isVideo;
   let media;

   if (isVideo && props.data.src) {
      media = <video className="video" src={props.data.src} autoPlay loop muted playsInline />
   } else if (props.data.src) {
      media = <img className="image" src={props.data.src} alt=""/>
   }

   return (
      <div className="sample-media">{media}</div>
   )
}

export default ProjectSample;
