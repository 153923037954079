import React from 'react';

import Container from '../../../components/elements/Container';
import ProjectIntro from '../../../components/elements/ProjectIntro';

import Divider from '../../../components/elements/Divider';
import MastheadText from '../../../components/elements/MastheadText';
import Previews from '../../../components/elements/Previews';

import './Home.css';

class Home extends React.Component {
   render() {
      return (
         <Container class="home">

            <MastheadText currentPage="home" />

            <div className="container-inner">

               <ProjectIntro
                  line="I'm a frontend developer based in Denver, Colorado."
                  lineTwo="Let's make something cool."
               />

               <Divider />

               <Previews />

               <Divider />

            </div>

         </Container>
      )
   }
}

export default Home;
